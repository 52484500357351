<template>
  <div class='d-flex flex-column flex-root'>
    <div
      class='login login-1 login-signin-on d-flex flex-row flex-row-fluid login-background py-5 py-sm-0'
      id='kt_login'
    >
      <b-row class="login-content">
        <b-col cols="12">
          <transition name="fade-short" mode="out-in">
            <router-view></router-view>
          </transition>
        </b-col>
      </b-row>
    </div>

    <Footer/>

  </div>
</template>

<!-- Load login custom page styles -->
<style lang='scss' scoped>

@import "@/assets/scss/login.scss";

.row {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: $mobile-breakpoint) {
  #view-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

@media (max-height: 700px) {
  #kt_login{
    flex: 1;
    padding-top: 32px !important;
    padding-bottom: 64px !important;
  }
}

</style>

<script>
import { mapActions, mapState } from 'vuex'
import Footer from '@/components/Footer'

export default {
  name: 'auth',
  methods: {
    ...mapActions({
      getAuthConfig: 'config/getAuthConfig'
    })
  },
  components: { Footer },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage () {
      return process.env.BASE_URL + 'media/bg/login.jpg'
    }
  },

  mounted () {
    this.getAuthConfig()
  }
}
</script>
